@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.close_button {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  width: 80px;
  min-width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;


  p {
    font-size: 14px;
  }
}



