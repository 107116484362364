@use "../../assets/styles/corners" as *;


.slidecontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .icon {
        padding-left: 20px;
        padding-right: 10px;
        min-width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            max-width: 50px;
            max-height: 50px;
        }
    }

    .slider {
        -webkit-appearance: none;
        width: 80%;
        height: 25px;
        background: #E0E0E0;
        outline: none;
        opacity: 0.9;
        border: #343434 solid 1px;
        -webkit-transition: .2s;
        transition: opacity .2s;
        @include rounded_small;
        box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25) inset;
    }
      
    .slider:hover {
        opacity: 1;
    }
    
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 60px;
        height: 20px;
        background: #343434;
        @include rounded_small;
        box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.25);
        
        cursor: pointer;
    }
    
    .slider::-moz-range-thumb {
        width: 60px;
        height: 20px;
        background: #343434;
        @include rounded_small;
        box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.25);
        
        cursor: pointer;
    }
}

