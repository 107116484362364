@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Jura';
    src: local('Jura'), url(../fonts/Jura/Jura-VariableFont_wght.ttf) format('truetype');
}


@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(../fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format('truetype');
}


$font_family-general: 'Jura', 'Inter', 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font_family-jura: 'Jura';
$font_family-montserrat: 'Montserrat';
$font_family-inter: 'Inter';

$font_weight-regular: 500;
$font_weight-bold: 600;
$font_weight-light: 400;

$font_size-small: 14px;
$font_size-medium: 18px;
$font_size-large: 22px;


b {
    font-weight: $font_weight-bold;
}

p {
    font-weight: $font_weight-regular;
    font-size: $font_size-small;
}

