@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.sidebar {
  top: 0px;
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 20;

  &--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--logo {
      // top: 0px;
      margin-top: 10px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h4 {
        margin-block-start: 10px;
        margin-block-end: 0px;
      }
      &--icon {
        width: 60px;
        height: 60px;
      }
    }

    &--close_button {
      margin-right: 10px;
    }
  }

  &--elements {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--element {
      margin-left: 20px;
      margin-right: 10px;
      font-family: $font_family-jura;
      display: flex;
      flex-direction: row;
      align-items: center;

      &--bagde {
        margin-right: 20px;
        background-color: $additional_color--red;
        color: $font_color--light;
        width: 24px;
        height: 24px;
        @include rounded_circle;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: $font_family-jura;
        font-size: $font_size-medium;
        font-weight: $font_weight-bold;
      }

      &--icon {
        margin-right: 20px;
        width: 24px;
        height: 24px;
        @include rounded_circle;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: $font_family-jura;
        font-size: $font_size-medium;
        font-weight: $font_weight-bold;
      }
    }
  }
}