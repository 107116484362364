
$radius_small: 6px;
$radius_medium: 10px;
$radius_high: 30px;

$radius_circle: 200px;


@mixin rounded_small {
  border-radius: $radius_small;
}

@mixin rounded_medium {
  border-radius: $radius_medium;
}

@mixin rounded_high {
  border-radius: $radius_high;
}

@mixin rounded_circle {
  border-radius: $radius_circle;
}
