@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";

.MiniMapView {
  text-align: center;
  height: 100%;
  width: 100%;
}

.minimap-container{
  height: 100%;
  width: 100%;
}

.clicked-coord-label{
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 5px;
}

.clicked-coord-label p {
  margin: 10px;
}


.minimap {
  height: 100%;
  width: 100%;
  /* height: 80%; */
}
