@use "../../assets/styles/corners" as *;

@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";

.MapView {
  height: 100%;
  width: 100%;

  &--elements {

    &--buttonbar-placement{
      position: fixed;
      z-index: 1;
      width: 100%;
      max-width: 600px;
      bottom: 10px;
    }

    &--buttonbar {
      // background-color: #F4F4F4;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      @include rounded_circle;
      padding: 20px;
      padding-left: 45px;
      padding-right: 45px;
      margin: 10px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &--item {
        width: 80px;
      }
    }
  }
}

.map-container{
  height: 100vh;
  width: 100%;
}

.clicked-coord-label{
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 5px;
}

.clicked-coord-label p {
  margin: 10px;
}


.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  /* height: 80%; */
}
