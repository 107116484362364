@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";



.Thumbler {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  width: 100px;
  height: 100px;
  @include rounded_medium;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 8px;
  margin: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.20) inset, 0px 0px 13px 0px rgba(0, 0, 0, 0.25);
  text-align: center;



  // theme:

  &--primary {
    @extend .Thumbler;
    color: $font_color--dark;
    background-color: $bg_color--layer;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $bg_color--layer;
    }

    p {
        color: $font_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  // &--green {
  //   color: $font_color--light;
  //   background-color: $additional_color--green;

  //   &:hover, &:active{
  //     color: $font_color--light;
  //     background-color: $color--primary-accent;
  //   }

  //   p {
  //       color: $bg_color--light;
  //       margin-block-start: 0em;
  //       margin-block-end: 0em;
  //   }
  // }

  &--green {
    width: 24px;
    height: 10px;
    background-color: #61C823;
    @include rounded_small;
  }

  &--orange {
    width: 24px;
    height: 10px;
    background-color: #C87C23;
    @include rounded_small;
  }

  &--red {
    width: 24px;
    height: 10px;
    background-color: #E90000;
    @include rounded_small;
  }

  &--violet {
    width: 24px;
    height: 10px;
    background-color: #A93DFF;
    @include rounded_small;
  }


  &--error {
    @extend .Thumbler;
    color: $font_color--dark;
    background-color: $color--error;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $color--error-accent;
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--disabled {
    @extend .Thumbler;
    color: $font_color--light;
    background-color: $bg_color--gray;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $bg_color--gray;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }
}







$color: #010000; // Try other colors, like #18ffa0
$rotation: 15deg;
$pivot-distance: 10px;
$width: 40px;
$height: 1.3 * $width;


.switch_container{
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    margin-top: calc($height + 50px);
  }

  margin-bottom: 10px;
  margin-top: 15px;
}

.switch {
  position: fixed;
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  background-color: black;
  width: $width;
  height: $height;
  box-shadow: 
    0 0 10px 2px rgba(black, 0.2), // The surrounding shadow (first layer)
    0 0 1px 2px black, // The surrounding shadow (second layer)
    inset 0 2px 2px -2px white, // The top white "shine"
    inset 0 0 2px 15px #47434c, // The light gray frame
    inset 0 0 2px 22px black; // The internal black shadow
  @include rounded_small;
  padding: 8px;
  perspective: 200px;

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  margin-bottom: 10px;

  
  input {
    display: none;
    
    &:checked + .button_thumbler {
      transform: translateZ($pivot-distance) rotateX($rotation);
      
      &--red {
        box-shadow: 0 -10px 20px #E90000;
      }

      &--green {
        box-shadow: 0 -10px 20px #61C823;
      }

      &--violet {
        box-shadow: 0 -10px 20px #A93DFF;
      }

      &--yellow {
        box-shadow: 0 -10px 20px #FFA318;
      }
      
      
      .light {
        animation: flicker 0.2s infinite 0.3s;
      }
      
      .shine {
        opacity: 1;
      }
      
      .shadow {
        opacity: 0;
      }
    }
  }
  
  .button_thumbler {
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    transform-origin: center center -#{$pivot-distance};
    transform: translateZ($pivot-distance) rotateX(-$rotation);
    transform-style: preserve-3d;
    background-color: #9b0621;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    background: linear-gradient(darken($color, 25%) 0%, darken($color, 33%) 30%, darken($color, 33%) 70%, darken($color, 25%) 100%);
    &--red {
      $color: #E90000;
      background: linear-gradient(darken($color, 25%) 0%, darken($color, 33%) 30%, darken($color, 33%) 70%, darken($color, 25%) 100%);
      &::before {
        background: linear-gradient(rgba(white, 0.8) 10%, rgba(white, 0.3) 30%, darken($color, 35%) 75%, darken($color, 45%)) 50% 50% / 97% 97%, darken($color, 20%);
      }
      &::after {
        background-image: linear-gradient(darken($color, 35%), darken($color, 45%));
      }
    }

    &--green {
      $color: #61C823;
      background: linear-gradient(darken($color, 25%) 0%, darken($color, 33%) 30%, darken($color, 33%) 70%, darken($color, 25%) 100%);
      &::before {
        background: linear-gradient(rgba(white, 0.8) 10%, rgba(white, 0.3) 30%, darken($color, 35%) 75%, darken($color, 45%)) 50% 50% / 97% 97%, darken($color, 20%);
      }
      &::after {
        background-image: linear-gradient(darken($color, 35%), darken($color, 45%));
      }
    }

    &--violet {
      $color: #A93DFF;
      background: linear-gradient(darken($color, 25%) 0%, darken($color, 33%) 30%, darken($color, 33%) 70%, darken($color, 25%) 100%);
      &::before {
        background: linear-gradient(rgba(white, 0.8) 10%, rgba(white, 0.3) 30%, darken($color, 35%) 75%, darken($color, 45%)) 50% 50% / 97% 97%, darken($color, 20%);
      }
      &::after {
        background-image: linear-gradient(darken($color, 35%), darken($color, 45%));
      }
    }

    &--yellow {
      $color: #FFA318;
      background: linear-gradient(darken($color, 25%) 0%, darken($color, 33%) 30%, darken($color, 33%) 70%, darken($color, 25%) 100%);
      &::before {
        background: linear-gradient(rgba(white, 0.8) 10%, rgba(white, 0.3) 30%, darken($color, 35%) 75%, darken($color, 45%)) 50% 50% / 97% 97%, darken($color, 20%);
      }
      &::after {
        background-image: linear-gradient(darken($color, 35%), darken($color, 45%));
      }
    }
    background-repeat: no-repeat;
    
    &::before {
      content: "";
      background-repeat: no-repeat;
      width: 100%;
      height: 50px;
      transform-origin: top;
      transform: rotateX(-90deg);
      position: absolute;
      top: 0;
    }
    
    &::after {
      content: "";
      width: 100%;
      height: 50px;
      transform-origin: top;
      transform: translateY(50px) rotateX(-90deg);
      position: absolute;
      bottom: 0;
      box-shadow: 0 50px 8px 0px black, 0 80px 20px 0px rgba(black, 0.5);
    }
  }
  
  .light {
    opacity: 0;
    animation:  light-off 1s;
    position: absolute;
    width: 100%;
    height: 100%;
   
    &--red {
      $color: #E90000;
      background-image: 
      // We use adjust-hue() here to make the center of the light a bit different in color
      // This is a nice tool for visualizing it https://sassme.jim-nielsen.com/
        radial-gradient(adjust-hue(lighten($color, 60%), 35), $color 40%, transparent 70%);
    }

    &--green {
      $color: #61C823;
      background-image: radial-gradient(adjust-hue(lighten($color, 60%), 35), $color 40%, transparent 70%);
    }

    &--violet {
      $color: #A93DFF;
      background-image: radial-gradient(adjust-hue(lighten($color, 60%), 35), $color 40%, transparent 70%);
    }

    &--yellow {
      $color: #FFA318;
      background-image: radial-gradient(adjust-hue(lighten($color, 60%), 35), $color 40%, transparent 70%);
    }
  }
  
  .dots {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(transparent 30%, rgba(darken($color, 35%), 0.7) 70%);
    &--red {
      $color: #E90000;
      background-image: radial-gradient(transparent 30%, rgba(darken($color, 35%), 0.7) 70%);
    }
    
    &--green {
      $color: #61C823;
      background-image: radial-gradient(transparent 30%, rgba(darken($color, 35%), 0.7) 70%);
    }

    &--violet {
      $color: #A93DFF;
      background-image: radial-gradient(transparent 30%, rgba(darken($color, 35%), 0.7) 70%);
    }

    &--yellow {
      $color: #FFA318;
      background-image: radial-gradient(transparent 30%, rgba(darken($color, 35%), 0.7) 70%);
    }
    background-size: 5px 5px;
  }
  
  .characters {
    position: absolute;
    width: 100%;
    height: 100%;
    
    // Drawing with gradients!
    // Read more about it here: https://css-tricks.com/drawing-images-with-css-gradients/
    // And check this collection out: https://a.singlediv.com/
    background:
      linear-gradient(white, white) 50% 20% / 5% 20%, // White vertical line
      radial-gradient(circle, transparent 50%, white 52%, white 70%, transparent 72%) 50% 80% / 33% 25%; // White circle
    background-repeat: no-repeat;
  }
  
  .shine {
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(white, transparent 3%) 50% 50% / 97% 97%,
      linear-gradient(rgba(white, 0.5), transparent 50%, transparent 80%, rgba(white, 0.5)) 50% 50% / 97% 97%;
    background-repeat: no-repeat;
  }
  
  .shadow {
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(transparent 70%, rgba(black, 0.8));
    background-repeat: no-repeat;
  }
}

@keyframes flicker {
  0% {opacity: 1}
  80% {opacity: 0.8}
  100% {opacity: 1}
}

@keyframes light-off {
  0% {opacity: 1}
  80% {opacity: 0}
}