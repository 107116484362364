

.Picker {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        &--item {
            min-width: 70px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}
