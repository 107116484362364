@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  &--small {
    width: 20%;
  }
  &--large {
    width: 100%;
  }
  position: fixed;
  z-index: 1;
  font-family: $font_family-montserrat;
}

.offmaps_logo {
  // background-color: $bg_color--light;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  // box-shadow: 0px 2px 12px $shadow_color--light;
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  &--bagde {
    position: fixed;
    top: -8px;
    right: -8px;
    background-color: $additional_color--red;
    color: $font_color--light;
    width: 24px;
    height: 24px;
    @include rounded_circle;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: $font_family-jura;
    font-size: $font_size-medium;
    font-weight: $font_weight-bold;
  }

  &--logo {
    
    margin: 0px;
    margin-left: 15px;
    margin-top: 4px;
    
  }
  &--menu {
    padding-left: 10px;
    font-family: $font_family-jura;
  }

  &--install {
    padding-left: 10px;
    margin-right: 30px;
  }

  img {   
    padding: 0px;
    margin: 0px;
    max-height: 60px;
    max-width: 130px;
  }
}

.restaurant_logo {
  background-color: $bg_color--light;
  border-radius: 10px;
  box-shadow: 0px 2px 12px $shadow_color--light;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  img {
    max-height: 50px;
    max-width: 130px;
  }
}

.sub_menu_button {
  background-color: $bg_color--light;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 2px 12px $shadow_color--light;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  
  img {
    max-height: 20px;
    max-width: 130px;
    margin-right: 10px;
  }
}
