@use "../../assets/styles/corners" as *;

@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";


.TrackAddViewer {
    z-index: 4;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    min-height: 70%;
    max-height: calc(100% - 80px);
    border-radius: 40px 40px 0px 0px;
    overflow-y: auto;    
    
    &--inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      &--label {
        display: block;
        margin-top: 20px;
        max-width: 400px;
      }

      &--chosen_file {
        margin-top: 10px;
        color: $additional_color--blue;
      }

      &--error_msg {
        color: $additional_color--red;
      }
    }

    &--file_choice {
      margin-right: 10px;
      margin-top: 30px;
    }
    
    &--description {
      margin-right: 10px;
      margin-top: 30px;
      
      &--area {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        width: 90%;
        min-height: 200px;
      }
    }

    &--email {
      margin-right: 10px;
      margin-top: 30px;

      &--input {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        width: 90%;
        height: 60px;
      }
    }

    &--elements {
        font-family: $font_family-montserrat;
        color: $bg_color--dark;
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: 15px;
        word-break: break-word;

        // .scrollable {
        //     position: fixed;
        //     overflow-y: auto;
        //     height: 100%;
        //     width: 100%;
        // }
        
        &--header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-right: 10px;
        }

        &--backward_button {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-right: 10px;
        }

        &--minimap {
            height: 200px;
            margin: 10px;
            margin-top: 15px;
            width: calc(100% - 30px);

            canvas {
                @include rounded_high;
            }
            
            &--placeholder{
                margin-top: 10px;
            }
        }

        &--buttonbar {
            background-color: #F4F4F4;
            @include rounded_circle;
            padding: 10px;
            margin-right: 10px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }
    
        h3 {
          font-size: 20px;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
    
        h4 {
          font-size: 14px;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
    
        .description {
          font-size: 14px;
        }
    
        .instagam {
          font-size: 12px;
        }
    
        a {
          font-size: 14px;
          margin-block-start: 10px;
          margin-block-end: 0em;
        }
    
        p {
          margin-block-start: 10px;
          margin-block-end: 0em;
        }
    
        input {
          margin-top: 5px;
          font-size: 14px;
          width: 90%;
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.TrackAddViewer::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.TrackAddViewer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}