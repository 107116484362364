@use "../../assets/styles/corners" as *;

@import "../../assets/styles/fonts";
@import "../../assets/styles/colors";


.TrackInfoViewer {
    z-index: 4;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    min-height: 70%;
    max-height: calc(100% - 80px);
    border-radius: 40px 40px 0px 0px;
    overflow-y: auto;    
    

    &--elements {
        font-family: $font_family-montserrat;
        color: $bg_color--dark;
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: 15px;
        word-break: break-word;

        // .scrollable {
        //     position: fixed;
        //     overflow-y: auto;
        //     height: 100%;
        //     width: 100%;
        // }
        
        &--header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-right: 10px;
        }

        &--minimap {
            height: 200px;
            margin: 10px;
            margin-top: 15px;
            width: calc(100% - 30px);

            canvas {
                @include rounded_high;
            }
            
            &--placeholder{
                margin-top: 10px;
            }
        }

        &--buttonbar {
            background-color: #F4F4F4;
            @include rounded_circle;
            padding: 10px;
            margin-right: 10px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }
    
        h3 {
          font-size: 20px;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
    
        h4 {
          font-size: 14px;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
    
        .description {
          font-size: 14px;
        }
    
        .instagam {
          font-size: 12px;
        }
    
        a {
          font-size: 14px;
          margin-block-start: 10px;
          margin-block-end: 0em;
        }
    
        p {
          margin-block-start: 10px;
          margin-block-end: 0em;
        }
        
        pre {
          font-family: $font_family-montserrat;
          white-space: pre-wrap;       /* Since CSS 2.1 */
          white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
          white-space: -pre-wrap;      /* Opera 4-6 */
          white-space: -o-pre-wrap;    /* Opera 7 */
          word-wrap: break-word;       /* Internet Explorer 5.5+ */
        }
    
        input {
          margin-top: 5px;
          font-size: 14px;
          width: 90%;
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.TrackInfoViewer::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.TrackInfoViewer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}