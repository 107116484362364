@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";



.button {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-bold;
  border: 0;
  // width: 100%;
  height: 40px;
  font-size: $font_size-large;
  @include rounded_medium;
  cursor: pointer;
  &--column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  line-height: 1;
  padding: 8px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(0, 0, 0, 0.20) inset;



  // theme:

  &--primary {
    @extend .button;
    color: $font_color--dark;
    background-color: $bg_color--layer;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $bg_color--layer;
    }

    p {
        color: $font_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--icon {
    padding-right: 10px;
  }

  // &--green {
  //   color: $font_color--light;
  //   background-color: $additional_color--green;

  //   &:hover, &:active{
  //     color: $font_color--light;
  //     background-color: $color--primary-accent;
  //   }

  //   p {
  //       color: $bg_color--light;
  //       margin-block-start: 0em;
  //       margin-block-end: 0em;
  //   }
  // }

  &--green {
    @extend .button;
    color: $font_color--light;
    background-color: #94BA7D;

    &:hover, &:active{
      color: $font_color--light;
      background-color: #94BA7D;
    }

    p {
        color: $font_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--blue {
    @extend .button;
    color: $font_color--light;
    background-color: #5197C7;

    &:hover, &:active{
      color: $font_color--light;
      background-color: #5197C7;
    }

    p {
        color: $font_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }


  &--error {
    @extend .button;
    color: $font_color--dark;
    background-color: $color--error;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $color--error-accent;
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--disabled {
    @extend .button;
    color: $font_color--light;
    background-color: $bg_color--gray;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $bg_color--gray;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }
}



