@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";


.lud_placeholder {
    background-color: lightgray;

    // styling
    &--styling__rounded {
        @include rounded_small;
    }

    // colors
    &--color__primary {
        background-color: $color--primary_disabled;
    }
    &--color__secondary {
        background-color: $color--secondary_disabled;
    }
    &--color__ternary {
        background-color: $color--ternary_disabled;
    }
    
}

// Animation
.placeholder-glow {
    .placeholder {
        animation: placeholder-glow 2s ease-in-out infinite;
    }
}
  
@keyframes placeholder-glow { 50% { opacity: 0.5;}}
  