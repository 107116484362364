@use "../../assets/styles/corners" as *;

@import "../../assets/styles/colors";
@import "../../assets/styles/fonts";



.circle_button {
  font-family: $font_family-montserrat;
  font-weight: $font_weight-regular;
  border: 0;
  width: 100px;
  height: 100px;
  @include rounded_circle;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.20) inset, 0px 0px 13px 0px rgba(0, 0, 0, 0.25);
  text-align: center;



  // theme:

  &--primary {
    @extend .circle_button;
    color: $font_color--dark;
    background-color: $bg_color--layer;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $bg_color--layer;
    }

    p {
        color: $font_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  // &--green {
  //   color: $font_color--light;
  //   background-color: $additional_color--green;

  //   &:hover, &:active{
  //     color: $font_color--light;
  //     background-color: $color--primary-accent;
  //   }

  //   p {
  //       color: $bg_color--light;
  //       margin-block-start: 0em;
  //       margin-block-end: 0em;
  //   }
  // }

  &--green {
    width: 24px;
    height: 10px;
    background-color: #61C823;
    @include rounded_small;
  }

  &--orange {
    width: 24px;
    height: 10px;
    background-color: #C87C23;
    @include rounded_small;
  }

  &--red {
    width: 24px;
    height: 10px;
    background-color: #E90000;
    @include rounded_small;
  }


  &--error {
    @extend .circle_button;
    color: $font_color--dark;
    background-color: $color--error;

    &:hover, &:active{
      color: $font_color--dark;
      background-color: $color--error-accent;
    }

    p {
        color: $bg_color--dark;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }

  &--disabled {
    @extend .circle_button;
    color: $font_color--light;
    background-color: $bg_color--gray;

    &:hover, &:active{
      color: $font_color--light;
      background-color: $bg_color--gray;
    }

    p {
        color: $bg_color--light;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
  }
}



